<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Create Statement</h1>
      </div>
      <StatementForm :viewMode="false" :eventName="'addStatement'" @addStatement="addStatement" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import StatementForm from '@/components/financial/StatementForm.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'CreateStatement',
  components: {
    StatementForm,
  },
  methods:{
    async addStatement(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const statementInfo = Object.assign({}, form);
        statementInfo.date = dayjs(statementInfo.date).unix();
        const statement = await Financial.createStatement(this.apiUrl, statementInfo, loginInfo);
        this.$router.back();
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
